import './App.css';
import Header from "./components/Header";
import Footer from "./components/Footer";
import React from "react";
import SectionHero from "./sections/SectionHero";
import SectionAbout from "./sections/SectionAbout";
import SectionResume from "./sections/SectionResume";
import SectionContact from "./sections/SectionContact";

function App() {
    return (
        <React.StrictMode>
            <Header/>
            <SectionHero/>
            <SectionAbout/>
            <SectionResume/>
            <SectionContact/>
            <Footer/>
        </React.StrictMode>
    );
}

export default App;
