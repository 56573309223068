import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEnvelope, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../components/ContactForm";

function SectionContact() {
    const contact = {
        h5: 'Contact',
        h1: 'I\'d Love To Hear From You.',
        p: 'Send me a message or just invite me for a coffee, let\'s see how it goes.',
        whereToFindMe: 'Where to find me',
        location: 'Prague, CZ',
        emailMeAt: 'Email Me At',
    }
    const email = 'mihajakse@gmail.com';

    return (
        <section id="contact">
            <div className="row section-intro">
                <div className="col-twelve">
                    <h5>{contact.h5}</h5>
                    <h1>{contact.h1}</h1>
                    <p className="lead">{contact.p}</p>
                </div>
            </div>
            <div className="row contact-form">
                <div className="col-twelve">
                    <ContactForm />
                </div>
            </div>
            <div className="row contact-info">
                <div className="col-six tab-full">
                    <div className="icon">
                        <FontAwesomeIcon icon={faMapMarkerAlt} size='lg'/>
                    </div>
                    <h5>{contact.whereToFindMe}</h5>
                    <p>{contact.location}</p>
                </div>
                <div className="col-six tab-full collapse">
                    <div className="icon">
                        <FontAwesomeIcon icon={faEnvelope} size='lg'/>
                    </div>
                    <h5>{contact.emailMeAt}</h5>
                    <p>
                        <a className="" href={`mailto:${email}`}>{email}</a>
                    </p>
                </div>
                {/*<div className="col-four tab-full">*/}
                {/*    <div className="icon">*/}
                {/*        <!--<i className="icon-phone"></i>-->*/}
                {/*        <i className="fa fa-2x fa-phone" aria-hidden="true"></i>*/}
                {/*    </div>*/}
                {/*    <h5>Call Me At</h5>*/}
                {/*    <p>*/}
                {/*        <span>Mobile:</span> <a className="" href="tel:+420771173851">(+420) 771 173 851</a><br>*/}
                {/*        <span>Mobile:</span> <a className="" href="tel:+38640304005">(+386) 40 304 005</a>*/}
                {/*    </p>*/}
                {/*</div>*/}
            </div>
        </section>
    );
}

export default SectionContact;