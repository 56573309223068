import HTMLReactParser from 'html-react-parser';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';

function SectionResume() {
    const resume = {
        title: 'Resume',
        heading: 'More of my credentials.',
        text: `Timeline of the past ${new Date().getFullYear() - 2017} years`,
        workExperienceTitle: 'Work Experience',
    };

    const experiences = [
        {
            position: 'Frontend developer',
            timeline: 'March 2021 - present',
            company: 'Make',
            description: 'Started in the Web department, mostly working with JS and Pug framework. Later on I got transferred into Product Engineering team, where I\'ve continued to upgrade my skills with the Angular ecosystem and PostgreSQL.',
        },
        {
            position: 'Frontend developer',
            timeline: 'February 2020 - February 2021',
            company: 'Educasoft solutions s.r.o.',
            description: 'Small startup where I was working mostly on their LMS systems <a href="https://competent.cz/" target="_blank">competent</a> and <a href="https://itrivio.cz/" target="_blank">iTrivio</a>. Code was written mostly in AngularJS framework.',
        },
        {
            position: 'Frontend developer',
            timeline: 'January 2018 - December 2019',
            company: 'DDB a.s.',
            description: 'Complete transformation of a McDonald\'s <a href="https://mcdonalds.cz" target="_blank" rel="nofollow">website</a> and <a href="https://restaurace.mcdonalds.cz" target="_blank" rel="nofollow">store locator</a> for Czechia and Slovakia, integrated with new custom CMS, including SEO and optimization. Worked on series of microsites and the main DDB Prague <a href="https://www.ddb.cz/" target="_blank">website</a>.',
        },
        {
            position: 'Web developer',
            timeline: 'January 2017 - November 2017',
            company: 'Onyx Group s.r.o.',
            description: 'Consulting with creative team, building websites from design to final product. Together with the creative team, we have developed a demo for <a href="https://www.nayvu.com/" target="_blank">nayvu</a> application. I was also in charge of the website of <a href="https://www.galerieharfa.cz/" target="_blank">galerieharfa.cz</a>',
        },
    ];

    return (
        <section id="resume" className="grey-section">
            <div className="row section-intro">
                <div className="col-twelve">
                    <h5>{resume.title}</h5>
                    <h1>{resume.heading}</h1>
                    <p className="lead">{resume.text}</p>
                </div>
            </div>
            <div className="row resume-timeline">
                <div className="col-twelve resume-header">
                    <h2>{resume.workExperienceTitle}</h2>
                </div>
                <div className="col-twelve">
                    <div className="timeline-wrap">
                        {experiences.map(function (experience, i) {
                            return <div className="timeline-block" key={i}>
                                <div className="timeline-ico">
                                    <FontAwesomeIcon icon={faCode} />
                                </div>
                                <div className="timeline-header">
                                    <h3>{experience.position}</h3>
                                    <p>{experience.timeline}</p>
                                </div>
                                <div className="timeline-content">
                                    <h4>{experience.company}</h4>
                                    <p>{HTMLReactParser(experience.description)}</p>
                                </div>
                            </div>;
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionResume;