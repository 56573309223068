import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

function SectionHero() {
    return (
        <section id="hero">
            <div className="hero-overlay"></div>
            <div className="hero-content">
                <div className="row">
                    <div className="col-twelve">
                        <h5>Hello.</h5>
                        <h1>I'm Miha Jakše.</h1>
                        <button className="button stroke" onClick={() => window.scrollTo({top: window.innerHeight, behavior: "smooth"})}>More About Me</button>
                    </div>
                </div>
            </div>
            <ul className="hero-social">
                <li>
                    <a href="https://www.linkedin.com/in/miha-jakse/" target="_blank">
                        <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                </li>
            </ul>
        </section>
    );
}

export default SectionHero;