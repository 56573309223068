import React, { useState } from 'react';
import { handleScrollToSection } from "../scripts/Scripts";

function Header() {
    const menuItems = [
        {
            title: 'Home', anchor: 'hero', scrollPosition: 0
        },
        {
            title: 'About', anchor: 'about', scrollPosition: 0
        },
        {
            title: 'Resume', anchor: 'resume', scrollPosition: 0
        },
        {
            title: 'Contact', anchor: 'contact', scrollPosition: 0
        }
    ];
    const [darkModeOn, setDarkModeOn] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [navListItemIndexActive, setNavListItemIndex] = useState(0);

    if (localStorage.getItem('dark') === 'true') {
        document.body.classList.add('dark');
    }

    const handleDarkModeToggle = () => {
        document.body.classList.toggle('dark');
        setDarkModeOn(!darkModeOn);
        localStorage.getItem('dark') === 'true' ?
            localStorage.removeItem('dark') : localStorage.setItem('dark', true);
    };

    const handleMenuClick = () => {
        setIsMenuOpen(current => !current);
    };

    const setActiveNavListItem = (index) => {
        setNavListItemIndex(index);
    }

    const handleMenuItemClick = (e, index) => {
        e.preventDefault();
        handleMenuClick();
        setActiveNavListItem(index);
        handleScrollToSection(menuItems[index].anchor);
    };

    return (
        <header>
            <div className="top-bar">
                <div
                    className={`menu-toggle ${isMenuOpen ? 'is-clicked' : ''}`}
                    onClick={handleMenuClick}
                >
                    <span>Menu</span>
                </div>
                <div className="logo">
                    <span>MJ</span>
                </div>
                <nav id="main-nav-wrap">
                    <ul
                        className={`main-navigation ${isMenuOpen ? 'show' : ''}`}
                    >
                        {menuItems.map(function (menu, i) {
                            return <li key={i} className={`${navListItemIndexActive === i ? 'current' : ''}`}>
                                <a
                                    onClick={e => handleMenuItemClick(e, i)}
                                    href={`#${menu.anchor}`}
                                    title={menu.title}>
                                    {menu.title}
                                </a>
                            </li>;
                        })}
                    </ul>
                </nav>
            </div>
            <div
                className={`theme-switcher ${darkModeOn ? `dark` : ``}`}
                onClick={handleDarkModeToggle}
            >
            </div>
        </header>
    );
}

export default Header;