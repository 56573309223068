import { useForm } from "react-hook-form";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function ContactForm() {
    const contactForm = {
        submitText: 'Submit',
        sendingText: 'Sending...',
        messageWasSent: 'Your message was sent, thank you!',
    }
    const {register, handleSubmit, formState: {errors, isSubmitted}} = useForm({
        defaultValues: {
            name: '',
            email: '',
            message: '',
        },
        criteriaMode: 'all',
    });
    const [isSending, setIsSending] = useState(false);
    const [wasSent, setWasSent] = useState(false);

    const onSubmit = data => {
        setWasSent(false);
        setIsSending(true);

        fetch('https://hook.eu1.make.com/u4yhgcwcemj86swxv082f8gq46uoca9o', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => {
                setIsSending(false);
                if (response.ok) {
                    setWasSent(true);
                }
            })
            .catch(error => {
                setIsSending(false);
                setWasSent(false);
                console.error('Error sending POST request:', error);
            });
    }

    const inputs = [
        {
            type: 'text',
            name: 'name',
            placeholder: 'Name',
            options: {
                required: 'Name is required',
                minLength: {
                    value: 3,
                    message: 'Name must be at least 3 characters',
                },
                maxLength: {
                    value: 80,
                    message: 'Name cannot exceed 80 characters',
                },
            }
        },
         {
            type: 'email',
            name: 'email',
            placeholder: 'Email',
            options: {
                required: 'Email is required',
                pattern: {
                    value: /^\S+@\S+$/i,
                    message: 'Please enter a valid email address',
                },
            }
        },
         {
            type: 'textarea',
            name: 'message',
            placeholder: 'Your message ...',
            options: {
                required: 'Message is required',
                minLength: {
                    value: 15,
                    message: 'Message must be at least 17 characters',
                },
            }
        }
    ]

    return (
        <div>
            {!wasSent
                ? (<form onSubmit={handleSubmit(onSubmit)}>
                    <fieldset>
                        {inputs.map(function (input, i) {
                            return <div className="form-field" key={input.name}>
                                {input.type === 'textarea' ? (
                                    <textarea
                                        placeholder={input.placeholder}
                                        autoComplete="off"
                                        {...register (input.name, {...input.options})}
                                    />
                                ) : (
                                    <input
                                        type={input.type}
                                        placeholder={input.placeholder}
                                        autoComplete="off"
                                        {...register (input.name, {...input.options})}
                                    />
                                )}
                                {/*{touchedFields[input.name] && errors[input.name] && (*/}
                                {/*    <label className="error">{errors[input.name].message}</label>*/}
                                {/*)}*/}
                                {errors[input.name] && (
                                    <label className="error">{errors[input.name].message}</label>
                                )}
                                <span className="border"></span>
                            </div>;
                        })}
                        <div className="form-field">
                            <button
                                className="btn-submit"
                                type="submit"
                                disabled={isSending}
                            >
                                {contactForm.submitText}
                            </button>
                            {isSending ?
                                <div>
                                    {/*<div className="text-loader">{contactForm.sendingText}</div>*/}
                                    <div className="s-loader">
                                        <div className="bounce1"></div>
                                        <div className="bounce2"></div>
                                        <div className="bounce3"></div>
                                    </div>
                                </div>
                                : null}
                        </div>
                    </fieldset>
                </form>)
                : (<div className="message-success align-center">
                    <FontAwesomeIcon icon={faCheck}/>
                    {contactForm.messageWasSent}
                </div>)
            }
        </div>
    );
}

export default ContactForm;