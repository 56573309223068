import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faLongArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { handleScrollToSection } from "../scripts/Scripts";
import { useEffect, useState } from "react";
function Footer() {
    const [showBackToTop, setShowBackToTop] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            // Adjust the threshold as needed
            if (scrollPosition > 200) {
                setShowBackToTop(true);
            } else {
                setShowBackToTop(false);
            }
        };

        // Attach the event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array ensures that the effect runs only once on mount


    const scrollToTop = (e, anchor) => {
        e.preventDefault();
        handleScrollToSection(anchor)
    }

    return (
        <footer>
            <div className="row">
                <div className="col-six tab-full pull-right social">
                    <ul className="footer-social">
                        <li>
                            <a href="https://www.linkedin.com/in/miha-jakse/" target="_blank">
                                <FontAwesomeIcon icon={faLinkedinIn} />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-six tab-full">
                    <div className="copyright">
                        <span>© Copyright Miha Jakše 2023.</span>
                    </div>
                </div>
                <div
                    className={`scroll-to-top ${showBackToTop ? 'show' : ''}`}
                >
                    <a
                        title="Back to Top"
                        href="#hero"
                        onClick={(e) => scrollToTop(e, 'hero')}
                    >
                        <FontAwesomeIcon icon={faLongArrowUp} />
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;