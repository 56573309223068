import { handleScrollToSection } from "../scripts/Scripts";

function SectionAbout() {
    const email = 'mihajakse@gmail.com';
    const about = {
        title: 'About',
        heading: 'Let me introduce myself.',
        text: 'Born and raised in Slovenia. After the high school I moved to the capital in Ljubljana to continue my education and develop work skills. As a pure coincidence life took me further to Prague, where I\'m currently still located.',
        profileTitle: 'Profile',
        profileText: 'Open minded and respectful to other people, no matter their beliefs or nationalities. Devoted to my work and inspired by people who inspire others. Not afraid of good critique, since I like to get things done in a right way.',
        skillsTitle: 'Skills',
        skillsText: 'Proficient in HTML, CSS & Javascript. Lately I\'m working mostly with JS frameworks: Angular, React & Vue.js. I\'m also familiar with SQL, PostgreSQL, Bootstrap, PHP, Nette Framework, Wordpress, NodeJS, Subversion & AngularJS. Very curious about the Web3 technologies.',
        hireMe: 'Hire me',
        downloadCv: 'Download CV',
        emailText: 'Email',
        nameAndSurnameText: 'Fullname',
        nameAndSurname: 'Miha Jakše',
        dateOfBirthText: 'Birth Date',
        dateOfBirth: 'August 08'
    };

    const skills = [
        {
            name: 'HTML5',
            percentage: '100',
        },
        {
            name: 'SCSS',
            percentage: '100',
        },
        {
            name: 'Javascript',
            percentage: '90',
        },
        {
            name: 'Angular',
            percentage: '90',
        },
        {
            name: 'Vue',
            percentage: '50',
        },
        {
            name: 'React',
            percentage: '30',
        },
        {
            name: 'PHP',
            percentage: '85',
        },
        {
            name: 'PostgreSQL',
            percentage: '50',
        },
    ];

    const scrollToContact = (e) => {
        e.preventDefault();
        handleScrollToSection('contact');
    }

    return (
        <section id="about">
            <div className="row section-intro">
                <div className="col-twelve">
                    <h5>{about.title}</h5>
                    <h1>{about.heading}</h1>
                    <div className="intro-info">
                        <picture>
                            <source srcSet="/assets/img/profile.webp" type="image/webp" />
                            <source srcSet="/assets/img/profile.jpg" type="image/jpg" />
                            <img src="/assets/img/profile.jpg" alt="Profile Picture" />
                        </picture>
                        <p className="lead">{about.text}</p>
                    </div>
                </div>
            </div>
            <div className="row about-content">
                <div className="col-six tab-full">
                    <h3>{about.profileTitle}</h3>
                    <p>{about.profileText}</p>
                    <ul className="info-list">
                        <li>
                            <strong>{about.nameAndSurnameText}:</strong>
                            <span>{about.nameAndSurname}</span>
                        </li>
                        <li>
                            <strong>{about.dateOfBirthText}:</strong>
                            <span>{about.dateOfBirth}</span>
                        </li>
                        <li>
                            <strong>{about.emailText}:</strong>
                            <span>
                                <a href={`mailto:${email}`}>{email}</a>
                            </span>
                        </li>
                    </ul>
                </div>
                <div className="col-six tab-full">
                    <h3>{about.skillsTitle}</h3>
                    <p>{about.skillsText}</p>
                    {/*<ul className="skill-bars">*/}
                    {/*    {skills.map(function (skill, i) {*/}
                    {/*        return <li key={i}>*/}
                    {/*            <div className="progress percent{skill.percentage}">*/}
                    {/*                <span>{skill.percentage}%</span>*/}
                    {/*            </div>*/}
                    {/*            <strong>{skill.name}</strong>*/}
                    {/*        </li>;*/}
                    {/*    })}*/}
                    {/*</ul>*/}
                </div>
            </div>
            <div className="row button-section">
                <div className="col-twelve">
                    <a
                        href="#contact"
                        title="{about.hireMe}"
                        className="button stroke"
                        onClick={e => scrollToContact(e)}
                    >
                        {about.hireMe}
                    </a>
                    <a
                        href="/assets/pdf/cv.pdf"
                        target="_blank"
                        title="Download CV"
                        className="button button-primary"
                    >
                        {about.downloadCv}
                    </a>
                </div>
            </div>
        </section>
);
}

export default SectionAbout;